* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Barlow', sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.fl-center {
  justify-content: center;
  align-items: center;
}

.fl-space-between {
  justify-content: space-between;
}

.fl-col {
  flex-direction: column;
}

.content-widthfix {
  max-width: 1200px;
  width: 100%;
  margin: 0 30px 0 10px;
}

.light-weight {
  font-weight: 300;  
}

.hevy-weight {
  font-weight: 600;
}

.btn-general {
  border: 1px solid;
  width: fit-content;
  padding: 15px 30px;
  cursor: pointer;
  transition: .3s;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 3px;
}

.small-button {
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 1rem;
}

.primary-btn {
  background-color: #b22f33;
  color: #fff !important;
  border: 1px solid #b22f33;
}

.primary-color {
  color: #b22f33 !important;
}

.ghost-btn {
  border: 1px solid #fff;
  color: #b22f33;
  background-color: #fff;
}

.secondary-color {
  color: #0f0f41 !important;
}

.primary-btn:hover {
  background-color: #9d1217;
}

.mg-left {
  margin-left: 10px;
}

.invalid-feedback {
  display: flex !important;
}