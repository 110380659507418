.cheque-box {
  border: 1px solid red;
  display: flex;
  gap: 30px;  
  height: 350px;
}

.date-box-c {
  text-align: right;
  margin-top: 40px;
  padding: 0 50px;
  letter-spacing: 1px;
}

.amount-cost-c {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0 50px;
}

.amount-cost-c > li {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.memo-for-c {
  margin-top: 50px;
  padding: 0 50px;
}