.header-continer, .custer-info-box, .custer-vehcial-info, .signature-field-bottom, .content-bill-of-sale-info  {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
}

.pdf-file-download {
  z-index: 1000;
}

.signature-field-bottom {
  border-bottom: none;
}

.content-sizing {
  max-width: 1300px;
  width: 100%;
}

.header-content-wrapper{
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}

.header-content-wrapper > * {
  width: 33%;
}

.header-content-wrapper > img {
  width: 340px;
}

.company-info-top-left {
  display: flex;
  flex-direction: column;
}

.company-info-top-left > p {
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  white-space: nowrap;
  color: rgb(76, 76, 76);
  gap: 10px;
  line-height: 1.7;
  font-size: 15px;
}

.company-info-top-left > p > i {
  color: #000;
}

.header-content-wrapper > h2 {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.header-content-wrapper > h2 > span {
  color: red;
  font-weight: 300;
}

.header-title-info {
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  font-size: 14px;
}

.custer-info-wrapper {
  flex-direction: column;
  display: flex;
}

.custer-info-wrapper > ul {
  list-style-type: none;
  display: grid;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  padding: 20px 0;
  gap: 10px;
}

.custer-info-wrapper > ul > li {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-width: thin;
  justify-content: flex-start;
  border-radius: 3px;
  gap: 20px;
  padding: 10px;
}

.custer-info-wrapper > ul > li > h3 {
  font-weight: 500;
  white-space: nowrap;
  font-size: 15px;
  color: darkblue;
}

.custer-info-wrapper > ul > li > span, .custer-vehcial-wrapper > ul > li > span {
  color: rgb(76, 76, 76);
  font-weight: 400;
}

.col-half {
  width: 49%;
}

.col-full {
  width: 100%;
}

.custer-vehcial-wrapper {
  display: flex;
  flex-direction: column;
}

.custer-vehcial-wrapper > ul {
  display: grid;
  width: 100%;
  padding: 20px 0;
  grid-gap: 10px;
  grid-template-columns: .4fr 1fr 1fr 1fr .6fr .5fr;
}

.custer-vehcial-wrapper > ul > li {
  display: flex;
  flex-direction: column;
  border: 2px solid #ddd;
  border-width: thin;
  border-radius: 3px;
  padding: 5px 10px;
  gap: 4px;
}

.custer-vehcial-wrapper > ul > li > h3 {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: darkblue;
}

.col-2-flex-fix {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  padding-top: 0 !important;
}

.signature-field-wrapper {
  padding: 20px 0;
  display: flex;
}

.signature-field-wrapper > ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 10px;
  justify-content: center;
  list-style-type: none;
}

.signature-field-wrapper > ul > li {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
}

.signature-field-wrapper > ul > li > div > p {
  font-size: 14px;
  line-height: 1.8;
  color: rgb(76, 76, 76);
  font-weight: 300;
}

.signature-field-wrapper > ul > li > div > h4, .difference > h4 {
  margin-bottom: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.sigature-box {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
  display: flex;
  width: 100%;
  padding: 5px;
  font-weight: 300;
}

.sigature-box > h4 {
  width: 100%;
  white-space: nowrap;
  font-weight: 300;
  padding-left: 5px;
  padding-top: 5px;
  margin-top: 50px;
  color: rgb(76, 76, 76);
  font-size: 14px;
  border-top: 1px solid;
}

.checkbox-information {
  width: 100%;
}

.checkbox-information > ul {
  display: flex;
  gap: 10px;
  border: 1px solid #ddd;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  margin: 5px 0;
  padding: 10px 10px;
  list-style-type: none;
}

.checkbox-information > p {
  font-size: 14px;
  color: rgb(76, 76, 76);
  font-weight: 400;
}

.checkbox-information > ul > li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100% !important;
}

.checkbox-information > ul > li > label {
  font-size: 12px;
  font-weight: 300;
  white-space: nowrap;
}

.container-space {
  width: 100%;
}


.content-bill-of-sale-wrapper {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  width: 100%;
}

.left-side-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;
  padding-bottom: 0;
}

.right-side-sale {
  padding: 20px;
  padding-right: 0;
  border-left: 1px solid #ddd;
}

.difference {

  border-radius: 3px;

}

.total-price {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total-price > li {
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ddd;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
} 

.total-price  > li > h3 {
  font-size: 14px;
  white-space: nowrap;
  font-weight: 500;
  color: rgb(47, 47, 51);
}

.total-price > li > span {
  font-weight: 300;
  font-size: 18px;
  color: rgb(123, 123, 123);
}

.total-price  > li > h2 {
  font-weight: 400;
  color: red;
}

.spacing-top-bottom {
  margin-bottom: 20px;
}

.flex-fix {
  display: flex !important;
  flex-direction: row !important;
  width: 50%;
  justify-content: space-between;
}

.flex-fix > * {
  width: 49% !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.flex-fix > * > h3 {
  font-size: 14px;
  font-weight: 500;
  color: darkblue;
  white-space: nowrap;
}

.pdf-file-download {
  background-color: #fff;
}

.dealer-invo {
  display: flex;
  align-items: flex-end;
  flex-direction: column;

}

.dealer-invo > h2 {
  color: rgb(202, 33, 33);
  display: flex;
  gap: 5px;
}

.dealer-invo > h2 > span {
  color: #000;
}

.companie-selection-container-field {
  position: relative;
}

.companies-selection {
  border: 1px solid #ddd;
  margin-top: 10px;
  width: 94.5%;
  overflow-y: scroll;
  max-height: 500px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 3px;
  background-color: #fff;
  z-index: 100;
  position: absolute;
}

.companies-selection > ul {
  list-style-type: none;
}

.companies-selection > ul > li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  transition: .3s;
  cursor: pointer;
}

.companies-selection > ul > li:hover {
  background-color: rgb(240, 240, 240);
}

.flex-3-group {
  display: flex;
  width: 100%;
  justify-content: center;
}

.flex-3-group-fd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.flex-3-group-fd > li {
  padding: 10px;
  flex-direction: column;
  display: flex;
  border: 1px solid #ddd;
  gap: 10px;
  width: 32.3%;
  font-weight: 300;
}

.flex-3-group-fd > li > strong {
  font-size: 15px;
  font-weight: 500;
}
